import React, { useState } from 'react'

import PrevNextNav from '../PrevNextNav'

export default function LogoListMobile({ partners }) {
  const itemsPerPage = 4
  const pageCount = Math.ceil(partners.edges.length / itemsPerPage)

  const [currentIndex, setCurrentIndex] = useState(0)
  const [viewAll, setViewAll] = useState(false)

  function nextPage() {
    let index = currentIndex + 1
    if (index >= pageCount) index = 0

    setCurrentIndex(index)
  }

  function prevPage() {
    let index = currentIndex - 1
    if (index < 0) index = pageCount - 1

    setCurrentIndex(index)
  }

  const renderedPartners = viewAll
    ? partners.edges
    : partners.edges.slice(
        itemsPerPage * currentIndex,
        itemsPerPage * currentIndex + itemsPerPage
      )

  return (
    <section className="container p-0">
      <div className="my-3 my-md-5 px-2 px-sm-0">
        <div className="c-logo-list-mobile mt-2">
          <div className="row">
            {renderedPartners.map(({ node: partner }, i) => (
              <div
                key={partner.id}
                className="col-6 d-flex align-items-center justify-content-center"
              >
                <div
                  className="c-logo-list-mobile__item is-visible"
                  style={{ animationDelay: `${60 * i}ms` }}
                >
                  <img src={partner.logo.publicURL} alt={partner.name} />
                </div>
              </div>
            ))}
          </div>
          <footer className="w-100">
            {!viewAll && pageCount > 1 ? (
              <div className="d-flex align-items-center mt-2 pt-2 border-top">
                <PrevNextNav
                  prevHandler={prevPage}
                  nextHandler={nextPage}
                  sober={true}
                />
                <button
                  class="c-logo-list-mobile__view-all text-uppercase text-secondary"
                  onClick={() => {
                    setViewAll(true)
                  }}
                >
                  View all
                </button>
              </div>
            ) : null}
          </footer>
        </div>
      </div>
    </section>
  )
}
