import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import './style.scss'

import LogoListFull from './LogoListFull'
import LogoListMobile from './LogoListMobile'
import MediaQuery from 'react-responsive'

const mobile = '(max-width: 767px)'
const desk = '(min-width: 768px)'

export default function LogoList(props) {
  const { partners } = useStaticQuery(graphql`
    query {
      partners: allPartnersYaml {
        edges {
          node {
            id
            name
            extended
            logo {
              publicURL
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <MediaQuery query={desk}>
        <LogoListFull {...props} partners={partners}></LogoListFull>
      </MediaQuery>
      <MediaQuery query={mobile}>
        <LogoListMobile {...props} partners={partners}></LogoListMobile>
      </MediaQuery>
    </>
  )
}
