import React, { Suspense, useState } from 'react'
import { ReactComponent as Arrow } from '../../gfx/arrow-right.svg'

let Flickity = null

if (typeof window !== `undefined`) {
  Flickity = React.lazy(() => import('react-flickity-component'))
}

export default function LogoListFull({ narrow, partners }) {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [flkty, setFlkty] = useState(null)
  const [flktySlideCount, setFlktySlideCount] = useState(0)

  const onFlktyChange = () => {
    setFlktySlideCount(flkty.slides.length)
  }

  const flickityOptions = {
    cellAlign: 'left',
    contain: true,
    pageDots: false,
    prevNextButtons: false,
    groupCells: true,
    wrapAround: true,
  }

  if (hasLoaded && flkty) {
    flkty.resize()
    flkty.select(0)
    flkty.on('change', onFlktyChange)
    onFlktyChange()
    setHasLoaded(false)
  }

  let imagesLoaded = 0

  return (
    <section className="container p-0">
      <div className="my-2 my-md-4 px-2 px-sm-0">
        <div className="row align-center">
          <div className={`${narrow ? 'col-sm-9' : 'col-sm-12'}`}>
            <div
              className="c-logo-list mt-2 mt-sm-0"
              onClick={() => {
                setHasLoaded(true)
              }}
            >
              {Flickity && (
                <Suspense fallback={<div>Loading...</div>}>
                  <Flickity
                    flickityRef={c => setFlkty(c)}
                    className={'carousel'} // default ''
                    elementType={'div'} // default 'div'
                    options={flickityOptions} // takes flickity options {}
                    disableImagesLoaded={false} // default false
                  >
                    {partners.edges.map(({ node: partner }) => (
                      <div className={partner.extended ? 'c-logo-list__item_extended' : 'c-logo-list__item'} key={partner.id}>
                        <img
                          onLoad={() => {
                            imagesLoaded++
                            if (imagesLoaded === partners.edges.length)
                              setHasLoaded(true)
                          }}
                          src={partner.logo.publicURL}
                          alt={partner.name}
                        />
                      </div>
                    ))}
                  </Flickity>
                  {flkty && flktySlideCount > 1 && (
                    <>
                      <button
                        onClick={() => {
                          flkty.previous()
                        }}
                        className="c-logo-list-nav c-logo-list-nav--prev"
                      >
                        <Arrow />
                      </button>
                      <button
                        onClick={() => {
                          flkty.next()
                        }}
                        className="c-logo-list-nav c-logo-list-nav--next"
                      >
                        <Arrow />
                      </button>
                    </>
                  )}
                </Suspense>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
